.Inner {
    border-top: 4px solid;
}

.Heading {
    @mixin h40;
    @mixin hyphenateWords manual;
    margin: 32px 0;
}

.Content {
    @mixin hyphenateWords manual;
    @mixin p30;
}

.Variant--light {
    color: var(--whiteBaseColor);

    & .Inner {
        border-color: var(--whiteBaseColor);
    }
}

.Variant--dark {
    color: var(--blackBaseColor);

    & .Inner {
        border-color: var(--blackBaseColor);
    }
}
